import logo from "./assets/logo.svg";
import app from "./assets/app.svg";
import "./App.css";

function App() {
  return (
    <div className="app">
      <header className="app_body">
        <div className="app_body_left">
          <img src={logo} className="app_logo" alt="logo" />
          <h1>
            Welcome to{" "}
            <span style={{ color: "#61dafb", fontWeight: "900" }}>
              Eyeswide.
            </span>
          </h1>
          <p>
            Eyes Wide connects like-minded people in your area that want to go
            out and do activities.
          </p>
        </div>
        <div className="app_body_right">
          <img src={app} className="app_image" alt="app" />
        </div>
      </header>

      <footer className="app_footer">
        <p className="app_footer_text">
          Email: contact@eyeswideapp.com{" "}
          <span style={{ margin: "0 10px" }}>&#124;</span>
          Address: 450 S MELROSE DR, STE 100 VISTA, CA 92081
        </p>
      </footer>
      <footer className="app_footer_mobile">
        <p>Email: contact@eyeswideapp.com</p>
        <p>Address: 450 S MELROSE DR, STE 100 VISTA, CA 92081</p>
      </footer>
    </div>
  );
}

export default App;
